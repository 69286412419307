@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  box-sizing: border-box !important;
  font-family: Inter;
  color: #374151;
}

/* Tailwind supporters */
.error-text {
  @apply text-xs text-error pt-0.5;
}

.flex-center {
  @apply flex justify-center items-center;
}

.focus-within\:border-primary:focus-within {
  @apply border-primary;
}

.modal-content-max-height {
  max-height: calc(100vh - 240px);
}

.scrollbar {
  @apply w-2 h-4 bg-gray-400 rounded-lg z-100;
}

.table-actions {
  @apply flex justify-start items-center space-x-4;
}

.text-medium-white {
  @apply text-base font-medium leading-normal;
}

.react-datepicker__close-icon::after {
  background-color: transparent !important;
  color: indigo !important;
  font-size: 24px !important;
  line-height: 0 !important;
}

/* Custom non tailwind styling */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

td[colspan='2']+td,
td[colspan='3']+td,
td[colspan='3']+td+td,
td[colspan='4']+td,
td[colspan='4']+td+td,
td[colspan='4']+td+td+td,
td[colspan='5']+td,
td[colspan='5']+td+td,
td[colspan='5']+td+td+td,
td[colspan='5']+td+td+td+td {
  @apply hidden;
}

.profile_user_roles_wrapper .w-max.absolute {
  position: absolute;
  top: 10px;
  left: 320px;
  min-width: 200px;
}

.top-ranger {
  top: 185px;
}

.top-ranger-icon {
  top: 235px;
}

.filter-search-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
}

.filter-modal-wrapper svg {
  width: 24px;
  height: 24px;
}