.password-input {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  box-sizing: border-box;
}

.password-container {
  position: relative;
}

.password-toggle-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}