:root {
  --toastify-toast-width: auto;
}

.Toastify__toast {
  align-items: center;
}

.Toastify__toast-icon {
  width: auto;
}

.Toastify__toast-theme--light {
  border-radius: 6px;
  background: inherit !important;
}

.Toastify__toast-body {
  font-family: Inter;
}

.Toastify__toast--success {
  background-color: #ecfdf5 !important;
}

.Toastify__toast--error {
  background-color: rgb(253, 214, 214) !important;
}
